import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import Box from "../../components/Box"
import { Container, Heading, Paragraph } from "../../components/components"
import Slider from "../../components/Slider/Slider"

const AskTheTeam = () => {
  const { mohammad, nils, boris } = useStaticQuery(query)
  return (
    <AskTheTeamWrapper>
      <Container>
        <Box style={{ paddingBottom: "24px" }}>
          <Heading tag="h1">Das sagt unser Team</Heading>
        </Box>

        <Slider>
          <TeamMember
            name="Mohammadreza Rad"
            position="Frontend Developer / Duales Studium"
            image={mohammad.image}
            quote={`Für mich ist es eine großartige Chance in diesem engagierten Team mitzuarbeiten und gemeinsam neue Wege zu beschreiten.<br /><br />Die Freiheit, eigene Ideen in die Tat umzusetzen und Entscheidungen eigenverantwortlich zu treffen, ist für mich besonders wichtig. Ambitionierte Mitarbeiter können die Zukunft des Unternehmens aktiv mit gestalten und sich dabei persönlich weiterentwickeln.`}
          />

          <TeamMember
            name="Boris Lange"
            position="Backend Developer / Software Architect"
            image={boris.image}
            quote="In den Projekten genießt das Team volles Vertrauen über den Einsatz neuer Technologien und Methoden, was die individuellen Aufgaben noch spannender macht. Spaß bei der Arbeit ist hier an der Tagesordnung. Das zeigt sich auch dadurch, dass regelmäßig im privaten Bereich gemeinsam etwas unternommen wird."
          />

          <TeamMember
            name="Nils Goecke"
            position="UI & UX Developer / Trainee"
            image={nils.image}
            quote="Nach Beginn meiner Ausbildung wurde ich in kurzer Zeit bereits aktiv in die Projektarbeit integriert und durch die erfahrenen Kollegen in neue Technologien eingeführt. Diese „First-Hand”-Erfahrung hat mir sehr dabei geholfen, das vermittelte Wissen auch in der Praxis einsetzen und früh erste Erfolge verzeichnen zu können."
          />
        </Slider>
      </Container>
    </AskTheTeamWrapper>
  )
}

const TeamMember: React.FC<{
  name: string
  position: string
  quote: string
  image: IGatsbyImageData
  style?: React.CSSProperties
}> = props => {
  return (
    <CardContainer style={{ ...props.style }}>
      <PersonWrapper>
        <PersonAvatar alt={props.name} image={getImage(props.image)!} />
        <PersonInformation>
          <PersonTitle>{props.name}</PersonTitle>
          <PersonDescription>{props.position}</PersonDescription>
        </PersonInformation>
      </PersonWrapper>
      <Quote dangerouslySetInnerHTML={{ __html: props.quote }} />
    </CardContainer>
  )
}

export default AskTheTeam

const query = graphql`
  query {
    mohammad: file(
      relativePath: { eq: "avatars/mohammadreza-farahanirad.jpeg" }
    ) {
      image: childImageSharp {
        gatsbyImageData(width: 60, height: 60, layout: FIXED)
      }
    }
    boris: file(relativePath: { eq: "avatars/boris-lange.jpeg" }) {
      image: childImageSharp {
        gatsbyImageData(width: 60, height: 60, layout: FIXED)
      }
    }
    nils: file(relativePath: { eq: "avatars/nils-goecke.jpeg" }) {
      image: childImageSharp {
        gatsbyImageData(width: 60, height: 60, layout: FIXED)
      }
    }
  }
`

const AskTheTeamWrapper = styled.div`
  width: 100%;
`

const CardContainer = styled.div`
  background-color: ${props => props.theme.palette.foregroundColor};
  padding: 32px;
  border-radius: ${props => props.theme.borderRadius};
`

const PersonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 64px;
`

const PersonAvatar = styled(GatsbyImage)`
  border-radius: 50px;
  flex: 0 0 auto;
  margin-right: 20px;
`

const PersonInformation = styled.div`
  display: flex;
  flex-direction: column;
  height: 0 1 100%;
`

const PersonTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  color: ${props => props.theme.palette.text.vibrant};
`

const PersonDescription = styled.div`
  font-size: 0.875rem;
  line-height: 1.5;
  color: ${props => props.theme.palette.text.onForeground};
`

const Quote = styled(Paragraph)`
  margin-top: 2rem;
  font-size: 0.875rem;
  color: ${props => props.theme.palette.text.onForeground};
  overflow: auto;
  scrollbar-width: 0;

  &::-webkit-scrollbar {
    display: none;
  }
`
