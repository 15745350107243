export type JobApplicationStep = {
  step: number
  title: string
  desc: string
  color: "red" | "blue" | "purple"
}
export const data: JobApplicationStep[] = [
  {
    color: "blue",
    step: 1,
    title: "Deine Bewerbung",
    desc: "Du bist überzeugt und hast Bock? Schick uns einfach deine Bewerbung. Solltest du noch keine Unterlagen zur Hand hast, schick uns doch einfach dein XING oder Linked-In Profil.",
  },
  {
    color: "red",
    step: 2,
    title: "Lerne das Team kennen",
    desc: "Wenn uns deine Bewerbung zusagt, würden wir dich gerne persönlich kennenlernen. Denn bei Incoqnito suchen wir die richtigen Persönlichkeiten und nicht den perfekten Lebenslauf.",
  },
  {
    color: "purple",
    step: 3,
    title: "Kleine Testaufgabe",
    desc: "Nach unserem Gespräch erhältst du eine kleine Testaufgabe, damit wir schauen können, wo du aktuell stehst und wie du deine besonderen Fähigkeiten in der Praxis einsetzen kannst.",
  },
  {
    color: "blue",
    step: 4,
    title: "Willkommen an Bord",
    desc: "Wir geben dir eine endgültige Rückmeldung innerhalb weniger Tage nach dem Erhalt der Testaufgabe. Falls alles wie erwartet funktioniert, begrüßen wir dich herzlich im Team!",
  },
]
