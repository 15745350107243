import { graphql } from "gatsby"
import React from "react"
import {
  Benefits,
  Column,
  Container,
  Head,
  Emphasized,
  Paragraph,
  Row,
  SecondarySection,
  Section,
  Spacer,
  ImageLayer,
  Heading,
} from "../../components/components"
import ApplicationProcedure from "../../widgets/ApplicationProcedure/ApplicationProcedure"
import AskTheTeam from "../../widgets/AskTheTeam/AskTheTeam"
import CallToAction from "../../widgets/CallToAction/CallToAction"
import CurrentJobOffers from "../../widgets/CurrentJobOffers/CurrentJobOffers"

const Career = ({ data }: { data: any }) => {
  return (
    <>
      <Head pagePath="/karriere" />
      <Section>
        <Container>
          <Row>
            <Column
              style={{ justifyContent: "center", alignItems: "center" }}
              lg={12}
            >
              <Heading tag="h1">
                Offene <Emphasized>Positionen</Emphasized>
              </Heading>
              <Spacer gutter={24} />
              <CurrentJobOffers />
            </Column>
            <Column />
          </Row>
        </Container>
      </Section>
      <SecondarySection>
        <Container>
          <Spacer gutter={240} />
          <Row style={{ margin: 0 }}>
            <Column
              lg={12}
              style={{
                alignItems: "center",
              }}
            >
              <ImageLayer
                color="purple"
                image={data.company1.image}
                alt="Das Incoqnito Team sitzt und steht lachend an einem Tisch und tauscht sich aus. Es wird ein Tablet und ein Laptop bedient."
              >
                <Heading tag="h1">Was uns wichtig ist</Heading>
                <Paragraph>
                  Wir sind stets auf der Suche nach ambitionierten
                  Persönlichkeiten, die sich gemeinsam mit uns weiterentwickeln
                  und Großartiges erschaffen wollen. Wenn du auf der Suche nach
                  einer neuen Herausforderung bist und Teil eines einzigartigen
                  Teams werden möchtest, bist du bei uns grundlegend richtig.
                  Jedoch suchen wir auch nicht jeden, denn die einzigartige
                  Persönlichkeit steht bei uns ganz klar vor dem perfekten
                  Lebenslauf.
                  <br />
                  <br />
                  Neben spannenden Projekten, Freiraum für eigene Kreativität
                  und zahlreichen Job-Benefits wollen wir bei Incoqnito auch
                  immer Spaß bei unserer Arbeit haben. Warum das so wichtig ist?
                  Die Arbeit nimmt einen bedeutenden Teil unseres Lebens ein.
                  Nur wenn wir Spaß an unserer Arbeit haben, können wir für
                  unsere Kunden einzigartige Lösungen entwickeln und
                  nachhaltigen Mehrwert schaffen – und somit zufrieden sein.
                </Paragraph>
              </ImageLayer>
            </Column>
          </Row>
        </Container>
        <Spacer gutter={80} />
        <AskTheTeam />
        <Spacer gutter={80} />
        {/* <YourProfileMatrix /> */}
        <Section fullHeight>
          <Benefits />
        </Section>
        <Spacer gutter={40} />
        <ApplicationProcedure />
      </SecondarySection>
      <CallToAction type="hiring" />
    </>
  )
}

export default Career

export const query = graphql`
  query {
    company1: file(relativePath: { eq: "career/wasUnsWichtigIst.jpg" }) {
      image: childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
  }
`
