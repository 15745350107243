import React from "react"
import styled from "styled-components"
import {
  Container,
  Heading,
  Paragraph,
  Spacer,
} from "../../components/components"
import { data, JobApplicationStep } from "./entries"
const ApplicationProcedure = () => {
  return (
    <Container>
      <Spacer gutter={40} />
      <Heading tag="h1">Ablauf des Bewerbungsprozesses</Heading>
      <Spacer gutter={40} />
      <FlexRow>
        {data.map((entry: JobApplicationStep) => {
          return (
            <OuterWrapper>
              <ApplicationStepWrapper>
                <StepContainer color={entry.color}>
                  <Heading
                    tag="h1"
                    style={{ lineHeight: "0", paddingBottom: "0" }}
                  >
                    {entry.step}
                  </Heading>
                </StepContainer>
                <Spacer gutter={24} />
                <Heading tag="h4">{entry.title}</Heading>
                <Paragraph>{entry.desc}</Paragraph>
              </ApplicationStepWrapper>
            </OuterWrapper>
          )
        })}
      </FlexRow>
      <Spacer gutter={80} />
    </Container>
  )
}

export default ApplicationProcedure

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.9rem;
  justify-content: center;

  ${p => p.theme.breakpoints.xl} {
    justify-content: unset;
  }
`
const OuterWrapper = styled.div`
  width: 23%;
  ${p => p.theme.breakpoints.xl} {
    width: 48%;
    justify-content: unset;
  }

  @media only screen and (max-width: 860px) {
    width: 46%;
  }

  ${p => p.theme.breakpoints.md} {
    width: 100%;
  }
`

const ApplicationStepWrapper = styled.div`
  padding: 32px;
  height: 100%;
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => props.theme.palette.foregroundColor};
`

const StepContainer = styled.div<{
  color: "red" | "blue" | "purple"
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: ${props => props.theme.borderRadius};
  background: ${props =>
    (props.color === "red" && props.theme.palette.red.light) ||
    (props.color === "blue" && props.theme.palette.blue.light) ||
    (props.color === "purple" && props.theme.palette.purple.light)};
  h1 {
    color: ${props =>
      (props.color === "red" && props.theme.palette.red.vibrant) ||
      (props.color === "blue" && props.theme.palette.blue.vibrant) ||
      (props.color === "purple" && props.theme.palette.purple.vibrant)};
  }
`
