import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import "swiper/css"
import "swiper/css/pagination"
import Slider from "../../components/Slider/Slider"
import JobOfferCard from "./JobOfferCard"

const CurrentJobOffers = () => {
  const data = useStaticQuery<Query>(query)
  const offerNodes = data.jobPosts.nodes.sort((a, b) => {
    return a.displayOrder - b.displayOrder
  })

  return (
    <CurrentJobOfferWrapper>
      <Slider>
        {offerNodes.map(offer => {
          return (
            <JobOfferCard
              company="Incoqnito GmbH"
              city="Hannover"
              title={offer.title}
              slug={offer.slug}
            />
          )
        })}
      </Slider>
    </CurrentJobOfferWrapper>
  )
}
const CurrentJobOfferWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 4px;
  margin-bottom: -260px;
  z-index: 10;
`

interface Query {
  jobPosts: {
    nodes: {
      title: string
      excerpt: string
      slug: string
      displayOrder: number
    }[]
  }
}

const query = graphql`
  query {
    jobPosts: allContentfulJobPost {
      nodes {
        title
        excerpt
        slug
        displayOrder
      }
    }
  }
`

export default CurrentJobOffers
