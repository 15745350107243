import { motion } from "framer-motion"
import React, { FC } from "react"
import styled from "styled-components"
import { ButtonLink, Icon, Spacer } from "../../components/components"
import { useTheme } from "../../styles/themes"

type JobOfferCardProps = {
  title: string
  city: string
  company: string
  slug?: string
}

const JobOfferCard: FC<JobOfferCardProps> = ({
  title,
  company,
  city,
  slug,
}) => {
  const { currentThemeName } = useTheme()

  return (
    <CardWrapper isDark={currentThemeName === "dark"}>
      <Content>
        <div>
          <IconWrapper>
            <CodeIcon />
          </IconWrapper>
          <Spacer gutter={24} />
          <Subline>
            {company} <span>/</span> {city}
          </Subline>
          <Title>{title}</Title>
        </div>
        <div style={{ marginTop: "2rem" }}>
          {slug && (
            <ButtonLink to={slug as any} arrowButton blue>
              Mehr Erfahren
            </ButtonLink>
          )}
        </div>
      </Content>
    </CardWrapper>
  )
}

export default JobOfferCard

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background: ${props => props.theme.palette.blue.light};
  border-radius: ${props => props.theme.borderRadius};
  width: fit-content;
`

const CodeIcon = styled(Icon.Code)`
  width: 24px;
  height: 24px;
  path {
    fill: ${props => props.theme.palette.blue.vibrant};
  }
`

const Subline = styled.p`
  font-size: 14px;
  color: ${props => props.theme.palette.text.light};
  padding: 0px;
  margin: 8px 0px;
`
const Title = styled.h4`
  font-size: 30px;
  color: ${props => props.theme.palette.text.vibrant};
  padding-top: 0;
  margin: 0;
`

const CardWrapper = styled(motion.div)<{
  isDark: boolean
}>`
  background: ${props => props.theme.palette.backgroundColor};
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: ${props =>
    props.isDark ? null : "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;"};
  /* box-shadow: 0 4px 30px rgb(11 33 74 / 11%), 0 -2px 5px rgb(11 33 74 / 3%); */
  display: flex;
  min-width: 350px;
  max-width: 700px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
  height: 100%;
`

const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  height: 100%;
  padding: 32px;
  position: relative;
  justify-content: space-between;
  z-index: 2;
`
